import Logo from "../Logo/Logo";
import NavBar from "../NavBar/NavBar";
import Button from "../../forms/Button/Button";
import chromeImg from "../../../assets/images/chrome.png";

import styles from "./styles.module.scss";

const Header = () => {
  return (
    <div className={`contentWrapper ${styles.header}`}>
      <div className={styles.logoNavWrapper}>
        <Logo />
        <NavBar />
      </div>
      <Button>
        <div className={styles.btnContent}>
          <img src={chromeImg} alt="Chrome Icon" />
          <span>Add to browser</span>
        </div>
      </Button>
    </div>
  );
};

export default Header;

const LoginRoutes = {
  path: "/",
  children: [
    {
      path: "/login",
      element: <div>login</div>,
    },
  ],
};

export default LoginRoutes;

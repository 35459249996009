import { Link } from "react-router-dom";

import Logo from "../Logo/Logo";
import { CONTACT, HOME, PRIVACY, TERMS } from "../../../routes/paths";

import styles from "./footer.module.scss";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className="contentWrapper">
        <div className={styles.wrapper}>
          <Logo />
          <nav>
            <ul className={styles.navbar}>
              <li>
                <Link to={HOME}>Home</Link>
              </li>
              <li>
                <Link to={TERMS}>Terms</Link>
              </li>
              <li>
                <Link to={PRIVACY}>Privacy</Link>
              </li>
              <li className={styles.contactMail}>
                <Link to={CONTACT}>Contact</Link>
                <a className={styles.mail} href="mailto:help@search-chat.ai">help@search-chat.ai</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Footer;
